

import { defineComponent, PropType } from "vue";
import {ICatalog} from "@/models/catalog";

import CatalogItem from "@/components/CatalogItem.vue";


export default defineComponent({
  components: {
    CatalogItem
  },
  props: {
    catalog: {
      type: Object as PropType<ICatalog>,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    getItemsBySectionId(sectionId:number){
      return this.catalog.items.filter(el=>el.sectionId == sectionId);
    }
  }
});
