
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    shadowInfo(e) {
      if (e.target.scrollHeight - e.target.clientHeight == e.target.scrollTop) {
        e.target.parentNode.querySelector(
          ".personal-data__shadow"
        ).style.display = "none";
      } else {
        e.target.parentNode.querySelector(
          ".personal-data__shadow"
        ).style.display = "block";
      }
    },
  },
});
