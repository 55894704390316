import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "feedback checkout" }
const _hoisted_2 = { class: "feedback__form" }
const _hoisted_3 = { class: "form-element" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "feedback__input-container" }
const _hoisted_6 = { class: "form-element" }
const _hoisted_7 = { class: "feedback__input-container" }
const _hoisted_8 = { class: "form-element" }
const _hoisted_9 = { class: "form-element feedback__area" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: _normalizeClass({ feedback__error: _ctx.v$.name.$error }),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
          placeholder: "ФИО"
        }, null, 2), [
          [_vModelText, _ctx.name]
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.name.$errors, (error) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "feedback__input-error",
          key: error.$uid
        }, _toDisplayString(error.$message), 1))
      }), 128)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: _normalizeClass({ feedback__error: _ctx.v$.email.$error }),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
              placeholder: "E-mail"
            }, null, 2), [
              [_vModelText, _ctx.email]
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.email.$errors, (error) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "feedback__input-error",
              key: error.$uid
            }, _toDisplayString(error.$message), 1))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.phone) = $event)),
              class: _normalizeClass({ feedback__error: _ctx.v$.phone.$error }),
              type: "text",
              placeholder: "Телефон",
              "data-maska": "+7 (9##) ###-##-##"
            }, null, 2), [
              [_vModelText, _ctx.phone],
              [_directive_maska]
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.phone.$errors, (error) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "feedback__input-error",
              key: error.$uid
            }, _toDisplayString(error.$message), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.text) = $event)),
          class: _normalizeClass({ feedback__error: _ctx.v$.text.$error }),
          cols: "30",
          rows: "8",
          placeholder: "Текст обращения"
        }, null, 2), [
          [_vModelText, _ctx.text]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.text.$errors, (error) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "feedback__input-error",
            key: error.$uid
          }, _toDisplayString(error.$message), 1))
        }), 128))
      ]),
      _createElementVNode("button", {
        disabled: _ctx.loading,
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.sendForm($event))),
        class: "feedback__btn basket__btn"
      }, [
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Отправить"))
          : _createCommentVNode("", true),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_12))
          : _createCommentVNode("", true)
      ], 8, _hoisted_10)
    ])
  ]))
}