
import { defineComponent, computed } from "vue";
import TheHeader from "@/layouts/TheHeader.vue";
import TheFooter from "@/layouts/TheFooter.vue";
import CatalogList from "@/components/CatalogList.vue";
import TheBasket from "@/components/TheBasket.vue";
import PickUpLocation from "@/components/PickUpLocation.vue";
import CatalogItemDetail from "@/components/CatalogItemDetail.vue";
import RecipeList from "@/components/RecipeList.vue";
import RecipeItemDetail from "@/components/RecipeItemDetail.vue";
import MakeOrder from "@/components/MakeOrder.vue";
import TheBeef from "@/components/TheBeef.vue";

import Main_swiper from "@/components/Main_swiper.vue";
import { recipes } from "@/models/model_item";
import { useStore } from "@/store";
import { $vfm } from "vue-final-modal";
import TheTest from "@/components/TheTest.vue";
import { toast } from "vue3-toastify";

export default defineComponent({
  components: {
    TheHeader,
    TheFooter,
    CatalogList, // eslint-disable-next-line
    TheBasket, // eslint-disable-next-line
    PickUpLocation,
    CatalogItemDetail,
    RecipeList,
    RecipeItemDetail, // eslint-disable-next-line
    MakeOrder,
    TheBeef,

    Main_swiper,
  },
  data() {
    return {
      loading: false,
      recipes,
      meatInfo: {},
    };
  },
  methods: {
    priceFormat(value: number) {
      return Intl.NumberFormat().format(value) + " ₽";
    },
    showBasket() {
      if (this.basket.items.length > 0) {
        $vfm.show({
          component: TheTest,
          bind: {
            title: "Ваш заказ",
          },
          slots: {
            content: {
              component: TheBasket,
            },
          },
        });
      } else {
        toast("Корзина пустая!", {
          position: "bottom-right",
          theme: "dark",
          progressStyle: {
            background: 'green'
          }
        });
      }
    },
    showMakeOrder() {
      $vfm.show({
        component: TheTest,
        bind: {
          title: "Оформление заказа",
        },
        slots: {
          content: {
            component: MakeOrder,
          },
        },
      });
    },
    showDeliveryType() {
      $vfm.show({
        component: TheTest,
        bind: { title: "Выберите способ получения" },
        slots: {
          content: {
            component: PickUpLocation,
            on: {
              success() {
                // if($vfm.dynamicModals[$vfm.dynamicModals.length-1]){
                //   $vfm.dynamicModals[$vfm.dynamicModals.length-1].value = false;
                // }
              },
            },
          },
        },
      });
    },

    openText(event: any) {
      let text = event.target.children[1];
      let svg = event.target.children[2];
      text.classList.toggle("app__payment-and-delivery-item-text--active");
      svg.classList.toggle("app__payment-and-delivery-svg--active");
      if (
        text.classList.contains("app__payment-and-delivery-item-text--active")
      ) {
        text.style.maxHeight = text.scrollHeight + "px";
        text.style.marginBottom = 24 + "px";
      } else {
        text.style.maxHeight = "0px";
        text.style.marginBottom = "0px";
      }
    },
  },
  mounted() {
    this.loading = true;
    let html = document.querySelector("body");
    if (html) {
      html.style.overflow = "hidden";
    }
    setTimeout(() => {
      this.loading = false;
      if (html) {
        html.style.overflow = "inherit";
      }
    }, 2500);
  },
  setup() {
    const store = useStore();

    store.dispatch("init").then((data) => {
      console.log("init");
    });

    store.dispatch("getBasket").then((data) => {
      console.log(data);
    });

    return {
      store,
      drawer: computed(() => store.state.drawer),
      catalog: computed(() => store.state.catalog),
      basket: computed(() => store.state.basket),
    };
  },
});
