<template>
  <div class="app__recipes" data-block="recipes">
    <div class="container">
      <h2 class="app__title app__title--recipes">Рецепты</h2>
      <ul class="app__recipes-list">
        <li
            class="app__recipes-item recipe"
            v-for="(recipe, idx) in items"
            :key="idx"
            @click="showDetail(recipe)"
        >
          <img
              v-lazy="{ src: recipe.image}"
              :alt="recipe.name"
          />
          <div class="recipe__gradient"></div>
          <div class="recipe__text">
            <h4 class="recipe__title">{{ recipe.name }}</h4>
            <div class="recipe__description">
                  <span class="recipe__time"
                  ><i class="recipe__time-svg"></i>
                    {{ recipe.time }}</span
                  >
              <span class="recipe__group">
                    <i class="recipe__group-svg"></i> × {{ recipe.persons }}</span
              >
            </div>
          </div>
        </li>
      </ul>
      <div class="app__pagination-body" v-if="false">
            <span class="app__pagination-container-svg">
              <i class="app__pagination-prev"></i>
            </span>
        <span class="app__pagination-number app__pagination-number--active"
        >1</span
        >
        <span class="app__pagination-number">2</span>
        <span class="app__pagination-number">3</span>
        <span class="app__pagination-number">4</span>
        <span class="app__pagination-number">5</span>
        <span class="app__pagination-number">6</span>
        <span class="app__pagination-container-svg">
              <i class="app__pagination-next"></i>
            </span>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {useStore} from "@/store";
import { $vfm } from "vue-final-modal";
import TheDetail from "@/components/TheDetail.vue";
import RecipeItemDetail from "@/components/RecipeItemDetail.vue";

export default defineComponent({
  components: {},
  props: {},
  data() {
    return {
      items: []
    };
  },
  methods: {
    showDetail(recipe) {
      $vfm.show({
        component: TheDetail,
        bind: {
          title: "Ваш заказ",
        },
        slots: {
          content: {
            component: RecipeItemDetail,
            bind: {
              item: recipe
            }
          },
        },
      });
    },
    openRecipePopup(item){
      this.store.commit('showDialog', {
        name: 'recipe',
        data: item
      });
    }
  },
  mounted() {
    this.store.dispatch("getRecipes").then((data) => {
      this.items = data.items;
    });
  },
  setup() {
    const store = useStore();
    return {store};
  }
});
</script>

<style scoped>

</style>
