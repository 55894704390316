import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25886086"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-head" }
const _hoisted_2 = { class: "modal-head__left" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "modal-head__title" }
const _hoisted_5 = { class: "modal-head__right" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, _mergeProps(_ctx.$attrs, {
    classes: "modal-container",
    "content-class": "modal-content"
  }), {
    default: _withCtx(({close, params}) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            class: "btn-back",
            onClick: close
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", {
            class: "btn-close",
            onClick: close
          }, null, 8, _hoisted_6)
        ]),
        _createTextVNode(_toDisplayString(_ctx.message), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _renderSlot(_ctx.$slots, "content", {
          params: params,
          message: "hello"
        }, undefined, true)
      ])
    ]),
    _: 3
  }, 16))
}