
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { defineComponent, PropType } from "vue";
import { IItem, ItemType } from "@/models/item";
import { useStore } from "@/store";
import { IOffer } from "@/models/offer";
import ACounter from "@/components/controls/ACounter.vue";
import { IBasketItem } from "@/models/basketItem";
import { $vfm } from "vue-final-modal";
import TheBasket from "@/components/TheBasket.vue";
import TheTest from "@/components/TheTest.vue";
import TheDetail from "@/components/TheDetail.vue";
import RecipeItemDetail from "@/components/RecipeItemDetail.vue";
import TheNotification from "./TheNotification.vue";
import NotificationContainer from "./NotificationContainer.vue";
import TheBeef from "./TheBeef.vue";

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<IItem>,
      required: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    TheBeef,
  },
  data() {
    return {
      selectedOffer: {},
      minOffer: {} as IOffer,
      detailInfo: null,
      invalid: false,
      loading: false,
      tm: 0,
      qt: 0,
    };
  },
  methods: {
    showDetail(recipe) {
      $vfm.show({
        component: TheDetail,
        bind: {
          title: "Ваш заказ",
        },
        slots: {
          content: {
            component: RecipeItemDetail,
            bind: {
              item: recipe,
            },
          },
        },
      });
    },
    showNotification(id) {
      $vfm.show({
        component: TheNotification,
        bind: {
          title: "Уведомить о поступлении",
        },
        slots: {
          content: {
            component: NotificationContainer,
            bind: {
              id: id,
            },
          },
        },
      });
    },
    showBasket() {
      $vfm.show({
        component: TheTest,
        bind: {
          title: "Ваш заказ",
        },
        slots: {
          content: {
            component: TheBasket,
          },
        },
      });
    },
    priceFormat(value: number) {
      return Intl.NumberFormat().format(value) + " ₽";
    },
    changeSelectedOffer(offer: IOffer) {
      this.selectedOffer = offer;
    },
    addBasket() {
      if (this.item.type == 3 && Object.keys(this.selectedOffer).length === 0) {
        console.log("sdas");
        this.invalid = false;
        if (this.tm) clearTimeout(this.tm);
        this.tm = setTimeout(() => (this.invalid = true), 1);
        this.tm = setTimeout(() => (this.invalid = false), 2000);
        const elementCoordinates = document.querySelector(
          ".info-popup-container__offers"
        );
        if (elementCoordinates) {
          elementCoordinates.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }
      } else {
        this.loading = true;
        if (this.item.type == 1) {
          this.store
            .dispatch("addBasket", {
              productId: this.item.id,
              quantity: this.item.ratio,
            })
            .then(() => {
              this.loading = false;
              console.log("end");
            });
        } else if (
          this.item.type == 3 &&
          Object.keys(this.selectedOffer).length > 0
        ) {
          this.loading = true;
          this.store
            .dispatch("addBasket", {
              productId: (this.selectedOffer as IOffer).id,
              quantity: (this.selectedOffer as IOffer).ratio,
            })
            .then(() => {
              this.loading = false;
              console.log("end");
            });
        }
      }
    },
    changeBasket(qt: number) {
      if (this.item.type == 1) {
        this.store
          .dispatch("addBasket", {
            productId: this.item.id,
            quantity: this.item.ratio,
          })
          .then(() => {
            console.log("end");
          });
      } else if (
        this.item.type == 3 &&
        Object.keys(this.selectedOffer).length > 0
      ) {
        this.store
          .dispatch("addBasket", {
            productId: (this.selectedOffer as IOffer).id,
            quantity: (this.selectedOffer as IOffer).ratio,
          })
          .then(() => {
            console.log("end");
          });
      }
    },
    close() {
      this.store.commit("closeDialog", "product");
    },
    openRecipe(item: any) {
      this.store.commit("showDialog", {
        name: "recipe",
        data: item,
      });
    },
  },
  computed: {
    price() {
      if (this.item.type == 3 && Object.keys(this.selectedOffer).length == 0) {
        return this.minOffer.price;
      } else if (this.item.type == 3) {
        return (this.selectedOffer as IOffer).price;
      }
      return this.item.price;
    },
    ratio() {
      if (this.item.type == 3 && Object.keys(this.selectedOffer).length == 0) {
        return this.minOffer.ratio;
      } else if (this.item.type == 3) {
        return (this.selectedOffer as IOffer).ratio;
      }
      return this.item.ratio;
    },
    measure() {
      if (this.item.type == 3 && Object.keys(this.selectedOffer).length == 0) {
        return this.minOffer.measure;
      } else if (this.item.type == 3) {
        return (this.selectedOffer as IOffer).measure;
      }
      return this.item.measure;
    },
    available() {
      if (this.item.type == 3 && Object.keys(this.selectedOffer).length == 0) {
        if (this.minOffer) return this.minOffer.available;
      } else if (this.item.type == 3) {
        return (this.selectedOffer as IOffer).available;
      }
      return this.item.available;
    },
    displayPrice() {
      if (this.item.type == 3 && Object.keys(this.selectedOffer).length == 0) {
        return "от  " + this.priceFormat(this.minOffer.price);
      } else if (this.item.type == 3) {
        return this.priceFormat((this.selectedOffer as IOffer).ratioPrice);
      }
      return this.priceFormat(this.item.ratioPrice);
    },
    displayDiscountPrice() {
      if (
        this.item.type == 3 &&
        Object.keys(this.selectedOffer).length > 0 &&
        (this.selectedOffer as IOffer).discountRatio
      ) {
        return this.priceFormat(
          (this.selectedOffer as IOffer).discountRatio +
            (this.selectedOffer as IOffer).ratioPrice
        );
      } else if (this.item.type == 1 && this.item.discountRatio) {
        return this.priceFormat(this.item.discountRatio + this.item.ratioPrice);
      }
      return null;
    },
    displayDiscountPercent() {
      if (this.item.type == 3 && Object.keys(this.selectedOffer).length > 0) {
        return (this.selectedOffer as IOffer).discountPercent;
      } else if (this.item.type == 1) {
        return this.item.discountPercent;
      }

      return 0;
    },
    displayInfo() {
      if (this.item.type == 3 && Object.keys(this.selectedOffer).length == 0) {
        if (this.minOffer.ratio != 1) {
          return (
            this.minOffer.ratio * 1000 +
            " г • " +
            this.priceFormat(this.minOffer.price) +
            "/" +
            this.minOffer.measure
          );
        }
      } else if (this.item.type == 3) {
        if (this.minOffer.ratio != 1) {
          return (
            (this.selectedOffer as IOffer).ratio * 1000 +
            " г • " +
            this.priceFormat((this.selectedOffer as IOffer).price) +
            "/" +
            (this.selectedOffer as IOffer).measure
          );
        }
      } else if (this.item.ratio != 1) {
        return (
          this.item.ratio * 1000 +
          "г • " +
          this.priceFormat(this.item.price) +
          "/" +
          this.item.measure
        );
      }
      return null;
    },
    displayImages() {
      if (this.item.type == 3 && Object.keys(this.selectedOffer).length == 0) {
        let offers = this.item.offers;
        if (offers && offers.length > 0) {
          return offers[0].images;
        }
      } else if (this.item.type == 3) {
        return (this.selectedOffer as IOffer).images;
      } else {
        return this.item.images;
      }

      return [];
    },
    quantity() {
      if (this.item.type == 3 && Object.keys(this.selectedOffer).length > 0) {
        let item: IBasketItem | undefined = this.store.state.basket.items.find(
          (item) => item.productId == (this.selectedOffer as IOffer).id
        );
        if (item) return item.quantity;
      } else {
        let item: IBasketItem | undefined = this.store.state.basket.items.find(
          (item) => item.productId == this.item.id
        );
        if (item) return item.quantity;
      }
      return 0;
    },
  },
  mounted() {

    
    if (this.item.type == ItemType.WitOffers) {
      this.minOffer = (this.item.offers as Array<IOffer>)[0];
      (this.item.offers as Array<IOffer>).forEach((offer) => {
        if (this.minOffer.ratioPrice > offer.ratioPrice) {
          this.minOffer = offer;
        }
      });

      if ((this.item.offers as Array<IOffer>).length == 1) {
        let offers = this.item.offers;
        if (offers) {
          this.selectedOffer = offers[0];
        }
      }
    }

    this.store.dispatch("getDetailProduct", this.item.id).then((data) => {
      this.detailInfo = data;
    }).finally(()=> {
      console.log(["init product", this.item, this.minOffer, this.detailInfo]);
    });
  },
  setup() {
    const store = useStore();
    return {
      store,
      modules: [Pagination],
    };
  },
});
