
import { computed, defineComponent, PropType, ref } from "vue";
import {$vfm} from "vue-final-modal";

export default defineComponent({
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    setTimeout(()=>{
      if ($vfm.dynamicModals[$vfm.dynamicModals.length - 1]) {
        $vfm.dynamicModals[$vfm.dynamicModals.length - 1].value =
            false;
      }
    }, 3000);
  }
});
