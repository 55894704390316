import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"padding":"10px"} }
const _hoisted_2 = { class: "app__cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CatalogItem = _resolveComponent("CatalogItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, idx) => {
        return (_openBlock(), _createBlock(_component_CatalogItem, {
          key: idx,
          item: item
        }, null, 8, ["item"]))
      }), 128))
    ])
  ]))
}