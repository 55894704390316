
import { integer } from "@vuelidate/validators";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    id: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    source: {
      type: Function,
      required: true,
    },
    delay: {
      type: Number,
      default: 600,
    },
    label: {
      type: String,
      default: "name",
    },
    ratio: {
      type: Number,
      default: 1,
    },
    price: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Number.MAX_VALUE,
    },
    measure: {
      type: String,
      default: "шт",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "change", "deleteItem"],
  data() {
    return {
      loadingCounter: false,
      value: 0,
      count: 1,
      tm: 0,
    };
  },
  mounted() {
    this.count = Math.round(Math.abs(this.modelValue / this.ratio));
    this.value = this.modelValue;
  },
  methods: {
    deleteItem() {
      this.$emit("deleteItem", this.id);
    },
    change(val: number) {

      this.loadingCounter = false;

      if (this.tm) clearTimeout(this.tm);

      if (this.value + val < this.min || this.value + val > this.max) return;

      this.tm = setTimeout(() => {
        this.loadingCounter = true;
      }, 600);

      this.value += val;
      this.count = Math.round(Math.abs(this.value / val));

      this.value = parseFloat(this.value.toFixed(2));

      this.$emit("change", this.value);
      this.$emit("update:modelValue", this.value);
    },
    priceFormat(value: number) {
      return Intl.NumberFormat().format(value) + " ₽";
    },
  },
  watch: {
    loading(e) {
      if (!e) {
        this.loadingCounter = false;
      }
    },
    modelValue(val: any) {
      this.value = val;
    },
  },
});
