import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "main-swiper" }
const _hoisted_2 = ["alt"]
const _hoisted_3 = ["alt"]
const _hoisted_4 = {
  key: 2,
  class: "swiper__shadow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      navigation: true,
      modules: _ctx.modules,
      pagination: {
        type: 'custom',
        renderCustom(swiper, current, total) {
          return current + ' | ' + total;
        },
      },
      class: "mySwiper"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, idx) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, {
            class: "swiper",
            key: idx
          }, {
            default: _withCtx(() => [
              (_ctx.width > 600)
                ? _withDirectives((_openBlock(), _createElementBlock("img", {
                    key: 0,
                    alt: image.preview
                  }, null, 8, _hoisted_2)), [
                    [_directive_lazy, { src: image.image }]
                  ])
                : _withDirectives((_openBlock(), _createElementBlock("img", {
                    key: 1,
                    alt: image.preview
                  }, null, 8, _hoisted_3)), [
                    [_directive_lazy, { src: image.mobileImage }]
                  ]),
              (_ctx.width < 600)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modules", "pagination"])
  ]))
}