
import { useStore } from "@/store";
import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { toast } from "vue3-toastify";
import { $vfm } from "vue-final-modal";


export default defineComponent({
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      tm: 0,
      email: "",
      loading: false,
    };
  },
  methods: {
    sendItem(e) {
      e.preventDefault();
      let formSend = {
        email: this.email,
        productId: this.id,
      };
      console.log(formSend);
      this.loading = true;
      this.v$.$validate().then((result) => {
        if (result) {
          this.tm = setTimeout(() => {
            this.store
              .dispatch("subscribeProduct", formSend)
              .then((data) => {
                console.log(data.text);
                toast(data.text, {
                  position: "bottom-right",
                  theme: "dark",
                  progressStyle: {
                    background: "green",
                  },
                });
                if ($vfm.dynamicModals[$vfm.dynamicModals.length - 1]) {
                  $vfm.dynamicModals[$vfm.dynamicModals.length - 1].value =
                    false;
                }
              })
              .catch((err) => {
                let data = err.response.data;
                let mesages = data.errors
                  .map((item) => item.message)
                  .join(", ");
                toast(mesages, {
                  position: "bottom-right",
                  theme: "dark",
                  progressStyle: {
                    background: "green",
                  },
                  type: "error",
                });
                this.loading = false;
                if ($vfm.dynamicModals[$vfm.dynamicModals.length - 1]) {
                  $vfm.dynamicModals[$vfm.dynamicModals.length - 1].value =
                    false;
                }
              })
              .finally(() => {
                this.loading = false;
              });
          }, 600);
        } else {
          this.loading = false;
        }
      });
    },
  },
  validations() {
    return {
      email: {
        email: helpers.withMessage("Не корректный формат", email),
        required: helpers.withMessage("Поле не должно быть пустым", required),
      },
    };
  },

  setup() {
    const store = useStore();
    return {
      v$: useVuelidate(),
      store,
    };
  },
});
