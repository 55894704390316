export interface IOffers {
  id: number;
  name?: string;
  price: number;
  gramm: number;
  priceGramm: number;
}

export interface IMeats {
  id: number;
  offers: Array<IOffers>;
  title: string;
  sale: number;
  recipes: Array<IRecipes>;
}

export interface IRecipes {
  id: number;
  imageName: string;
  title: string;
  time: number;
  group: string;
}

export const recipes: Array<IRecipes> = [
  {
    id: 1,
    group: '2',
    imageName: 'recipe_1',
    time: 20,
    title: 'Стейк Рибай'
  },
  {
    id: 2,
    group: '2',
    imageName: 'recipe_2',
    time: 40,
    title: 'Стейк Портерхаус'
  },
  {
    id: 3,
    group: '3',
    imageName: 'recipe_3',
    time: 25,
    title: 'Стейк Стриплойн'
  },
  {
    id: 4,
    group: '4',
    imageName: 'recipe_4',
    time: 20,
    title: 'Говяжья голень “По-милански” для запекания'
  },
  {
    id: 5,
    group: '2',
    imageName: 'recipe_5',
    time: 20,
    title: 'Яичница с беконом'
  },
  {
    id: 6,
    group: '1-2',
    imageName: 'recipe_6',
    time: 20,
    title: 'Медово-горчичный соус'
  },
  {
    id: 7,
    group: '2',
    imageName: 'recipe_7',
    time: 30,
    title: 'Пельмени в горшочке'
  },
  {
    id: 1,
    group: '1',
    imageName: 'recipe_8',
    time: 35,
    title: 'Пельмени на шпажках'
  },
]

export const meats: Array<IMeats> = [
  {
    id: 1,
    offers: [
      {
        id: 1,
        name: "Prime",
        price: 20000,
        gramm: 460,
        priceGramm: 17900,
      },
      {
        id: 2,
        name: "Choise",
        price: 15000,
        gramm: 320,
        priceGramm: 12900,
      },
      {
        id: 3,
        name: "Select",
        price: 10000,
        gramm: 180,
        priceGramm: 9900,
      },
    ],
    title: "Стейк стриплойн говяжий Black Angus",
    sale: 22,
    recipes: recipes,
  },
  {
    id: 2,
    offers: [
      {
        id: 1,
        name: "Prime",
        price: 20000,
        gramm: 460,
        priceGramm: 17900,
      },
      {
        id: 2,
        name: "Choise",
        price: 15000,
        gramm: 320,
        priceGramm: 12900,
      },
      {
        id: 3,
        name: "Select",
        price: 10000,
        gramm: 180,
        priceGramm: 9900,
      },
    ],
    title: "Стейк стриплойн говяжий Black Angus",
    sale: 0,
    recipes: recipes,
  },
  {
    id: 3,
    offers: [
      {
        id: 1,
        price: 10000,
        gramm: 460,
        priceGramm: 12900,
      },
    ],
    title: "Стейк стриплойн говяжий Black Angus",
    sale: 22,
    recipes: recipes,
  },
  {
    id: 4,
    offers: [
      {
        id: 1,
        name: "Prime",
        price: 20000,
        gramm: 460,
        priceGramm: 17900,
      },
      {
        id: 2,
        name: "Choise",
        price: 15000,
        gramm: 320,
        priceGramm: 12900,
      },
      {
        id: 3,
        name: "Select",
        price: 10000,
        gramm: 180,
        priceGramm: 9900,
      },
    ],
    title: "Стейк стриплойн говяжий Black Angus",
    sale: 0,
    recipes: recipes,
  },
];
