
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  data: () => ({
    width: window.innerWidth,
    currentPage: 0,
    images: [],
  }),
  components: {
    Swiper,
    SwiperSlide,
  },

  mounted() {
    this.store.dispatch("getBannersMain").then((data) => {
      this.images = data;
    });
  },

  setup() {
    const store = useStore();
    return {
      store,
      modules: [Pagination, Navigation],
    };
  },

  methods: {
    updateWidth() {
      this.width = window.innerWidth;
    },
    onOpenMenu() {
      document
        .querySelector(".menu-popup")
        ?.classList.add("menu-popup--active");
      document.querySelector("body")?.classList.add("body--active");
    },
    onCloseMenu() {
      document
        .querySelector(".menu-popup")
        ?.classList.remove("menu-popup--active");
      document.querySelector("body")?.classList.remove("body--active");
    },
  },

  created() {
    window.addEventListener("resize", this.updateWidth);
  },
});
