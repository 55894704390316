
import { defineComponent } from "vue";
import { $vfm } from "vue-final-modal";
import TheDetail from "@/components/TheDetail.vue";
import TheBeefDetail from "@/components/TheBeefDetail.vue";
import TheTest from "@/components/TheTest.vue";

export default defineComponent({
  props: {
    piece: {
      type: String,
    },
  },
  methods: {
    showProducts(title, code) {
      $vfm.show({
        component: TheTest,
        bind: {
          title: title,
        },
        slots: {
          content: {
            component: TheBeefDetail,
            bind: {
              code: code,
            },
          },
        },
      });
    },
  },
});
