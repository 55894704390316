import http from "@/http-common";

class Api{

    authGetCode(data){
        return http.post('/auth/get-code',data);
    }

    authLogin(data){
        return http.post('/auth/login',data);
    }

    authLogout(){
        return http.get('/logout');
    }

    orders(){
        return http.get('/orders');
    }

    order(id:number){
        return http.get('/orders/' + id);
    }

    userGet(){
        return http.get('/user');
    }

    userСhanges(data){
        return http.post('/user',data);
    }
    
    checkout(data){
        return http.post('/checkout',data);
    }

    addBasket(data){
        return http.post("/basket", data);
    }

    addCard(){
        return http.get("/card/add");
    }

    getCardList(){
        return http.get("/card/list");
    }
}

export default Api;
