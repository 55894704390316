
import FeedbackContainer from "@/components/FeedbackContainer.vue";
import PersonalData from "@/components/PersonalData.vue";
import TheFeedback from "@/components/TheFeedback.vue";
import ThePersonalData from "@/components/ThePersonalData.vue";
import { defineComponent } from "vue";
import { $vfm } from "vue-final-modal";
export default defineComponent({
  methods: {
    showFeedback(){
      $vfm.show({
        component: TheFeedback,
        bind: {
          title: "Обратная связь",
        },
        slots: {
          content: {
            component: FeedbackContainer,
          },
        },
      });
    },
    showPersonalData() {
      $vfm.show({
        component: ThePersonalData,
        bind: {
          title: "Политика конфедициальности",
        },
        slots: {
          content: {
            component: PersonalData,
          },
        },
      });
    },
  }
});
