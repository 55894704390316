import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";
import { ICatalog } from "@/models/catalog";

import http from "@/http-common";
import { IBasketTotal, IBasket } from "@/models/basket";
import { IBasketItem } from "@/models/basketItem";
import { IDeliveryPoint } from "@/models/deliveryPoint";
import { IPickupPoint } from "@/models/pickupPoint";

interface IDrawerData {
  display: boolean;
  data: any;
}

interface IDrawerList {
  basket: IDrawerData;
  order: IDrawerData;
  product: IDrawerData;
  recipe: IDrawerData;
  location: IDrawerData;
}

interface IUser{
  id: number;
  birthdate: string;
  email: string;
  name: string;
  phone: string;
}

export interface State {
  count: number;
  catalog: ICatalog;
  basket: IBasket;
  drawer: IDrawerList;
  deliveryPoint: IDeliveryPoint | null;
  deliveryType: string | null;
  pickupPoint: IPickupPoint | null;
  isAuthorized: boolean;
  user: IUser | null;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    count: 0,
    catalog: {
      items: [],
      sections: [],
    },
    basket: {
      items: [],
      total: {
        basePrice: 0,
        discount: 0,
        discountPercent: 0,
        price: 0,
      },
    },
    drawer: {
      product: {
        display: false,
        data: null,
      },
      basket: {
        display: false,
        data: null,
      },
      order: {
        display: false,
        data: null,
      },
      recipe: {
        display: false,
        data: null,
      },
      location: {
        display: false,
        data: null,
      },
    },
    deliveryType: null,
    deliveryPoint: null,
    pickupPoint: null,
    isAuthorized: false,
    user: null
  },
  mutations: {
    setCatalog(state, catalog) {
      state.catalog = catalog;
    },
    setBasket(state, basket) {
      state.basket = basket;
    },
    closeDialog(state, name) {
      switch (name) {
        case "product":
          state.drawer.product.display = false;
          state.drawer.product.data = null;
          break;
        case "recipe":
          state.drawer.recipe.display = false;
          state.drawer.recipe.data = null;
          break;
        case "basket":
          state.drawer.basket.display = false;
          state.drawer.basket.data = null;
          break;
        case "location":
          state.drawer.location.display = false;
          state.drawer.location.data = null;
          break;
      }
    },
    showDialog(state, data) {
      switch (data.name) {
        case "product":
          state.drawer.product.display = true;
          state.drawer.product.data = data.data;
          break;
        case "recipe":
          state.drawer.recipe.display = true;
          state.drawer.recipe.data = data.data;
          break;
        case "basket":
          state.drawer.basket.display = true;
          state.drawer.basket.data = data.data;
          break;
        case "location":
          state.drawer.location.display = true;
          state.drawer.location.data = data.data;
          break;
      }
    },
    setDeliveryPoint(state, data) {
      state.deliveryPoint = data;
    },
    setPickupPoint(state, data) {
      state.pickupPoint = data;
    },
    setDeliveryType(state, data) {
      state.deliveryType = data;
    },
    setAuthorized(state, data) {
      state.isAuthorized = data;
    },
    setUser(state, data) {
      state.user = data;
    },
  },
  actions: {
    init(context) {
      return http.get("/init").then((resp) => {
        const data = resp.data;
        if (data.location && data.location.deliveryPoint) {
          context.commit("setDeliveryType", data.location.deliveryType);
          context.commit("setDeliveryPoint", data.location.deliveryPoint);
          context.commit("setPickupPoint", data.location.pickupPoint);
        }

        if (data.catalog) {
          context.commit("setCatalog", data.catalog);
        }

        if (data.isAuthorized) {
          context.commit("setAuthorized", data.isAuthorized);
          context.commit("setUser", data.user);
        }

        return data;
      });
    },
    getCatalog(context) {
      return http.get("/catalog").then((resp) => {
        const data = resp.data;
        context.commit("setCatalog", data);
      });
    },
    deleteItemBasket(context, id) {
      return http.delete("/basket/" + id).then((resp) => {
        const data = resp.data;
        return data;
        // context.commit('setBasket', data);
      });
    },
    addBasket(context, data) {
      return http.post("/basket", data).then((resp) => {
        const data = resp.data;
        context.commit("setBasket", data);
      });
    },
    deleteAllItemsBasket(context) {
      return http.delete("/basket").then((resp) => {
        const data = resp.data;
        return data;
        // context.commit("setBasket", data);
      });
    },
    getBasket(context) {
      return http.get("/basket").then((resp) => {
        const data = resp.data;
        context.commit("setBasket", data);
      });
    },
    updateBasket(context, data) {
      return http.put("/basket", data).then((resp) => {
        const data = resp.data;
        context.commit("setBasket", data);
      });
    },
    getSearchItems(context, q) {
      return http.get("/search?q=" + q).then((resp) => {
        const data = resp.data;
        return data;
        // context.commit("setBasket", data);
      });
    },
    getBannersMain(context) {
      return http.get("/banners/main").then((resp) => {
        const data = resp.data;
        return data;
        // context.commit("setBasket", data);
      });
    },
    subscribeProduct(context, data){
      return http.post("/form/product-subscribe", data).then((resp) => {
        const data = resp.data;
        return data;
        // context.commit("setBasket", data);
      });
    },
    feedbackForm(context, data){
      return http.post("/form/feedback", data).then((resp) => {
        const data = resp.data;
        return data;
        // context.commit("setBasket", data);
      });
    },
    getSuggestionsAddress(context, q) {
      return http.get("/location/suggestions?q=" + q).then((resp) => {
        const data = resp.data;
        return data;
      });
    },
    getLocationByCoordinate(context, coords) {
      return http
        .get("/location?lat=" + coords[0] + "&lon=" + coords[1])
        .then((resp) => {
          const data = resp.data;
          return data;
        });
    },
    getDetailProduct(context, id) {
      return http.get("/product/" + id).then((resp) => {
        const data = resp.data;
        return data;
      });
    },
    getRecipes(context) {
      return http.get("/recipes").then((resp) => {
        const data = resp.data;
        return data;
      });
    },
    getDetailRecipe(context, id) {
      return http.get("/recipes/" + id).then((resp) => {
        const data = resp.data;
        return data;
      });
    },
    setLocation(context, data) {
      return http.post("/location", data).then((resp) => {
        const d = resp.data;
        if (d.status) {
          context.commit("setDeliveryType", data.type);

          if (data.type == "delivery") {
            context.commit("setDeliveryPoint", data.deliveryPoint);
          } else if (data.type == "pickup") {
            context.commit("setPickupPoint", data.pickupPoint);
          }
          console.log("setLocation", data);
        }
        return d;
      });
    },
    checkout(context, data) {
      return http
        .post("/checkout", data)
        .then((resp) => {
          const d = resp.data;
          return d;
        })
        .catch((err) => {
          return {
            shipments: [],
          };
        });
    },
    locationInit(context, data) {
      return http
        .get("/location/init", data)
        .then((resp) => {
          const d = resp.data;
          return d;
        })
        .catch((err) => {
          return {};
        });
    },
  },
});

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}
