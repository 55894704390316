
interface IError {
  code: number;
  message: string;
}

import { computed, defineComponent, PropType, ref, inject } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers, integer } from "@vuelidate/validators";
import { useStore } from "@/store";
import { $vfm } from "vue-final-modal";
import TheTest from "@/components/TheTest.vue";
import AuthPhoneConfirm from "@/components/AuthPhoneConfirm.vue";
import Api from "@/services/api";

export default defineComponent({
  data() {
    return {
      loading: false,
    };
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    success: {
      type: Function,
    },
  },
  emits: ["suc"],
  methods: {
    submit() {
      this.loading = true;
      let success = () => {
        if (this.success) this.success();
      };
      this.v$.$validate().then((result) => {
        if (result) {
          this.api
            .authGetCode({
              phone: this.phone,
            })
            .then((resp) => {
              let data = resp.data;
              if ($vfm.dynamicModals[$vfm.dynamicModals.length - 1]) {
                $vfm.dynamicModals[$vfm.dynamicModals.length - 1].value = false;
              }
              $vfm.show({
                component: TheTest,
                bind: { title: "" },
                slots: {
                  content: {
                    component: AuthPhoneConfirm,
                    bind: {
                      phone: this.phone,
                      otp: data.otp,
                    },
                    on: {
                      success,
                    },
                  },
                },
              });
            })
            .catch((err) => {
              let data = err.response.data;
              this.errors = data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
  validations() {
    return {
      phone: {
        required: helpers.withMessage("Обязательное поле", required),
        phone: helpers.withMessage(
          "Не корректный формат",
          helpers.regex(/\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/)
        ),
      },
    };
  },
  setup() {
    const store = useStore();

    let errors = ref<Array<IError>>([]);

    const api = inject<Api>("api", () => new Api(), true);

    let phone = ref<string | null>(null);

    return {
      api,
      phone,
      store,
      errors,
      v$: useVuelidate(),
    };
  },
});
