
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { computed, defineComponent, inject, PropType, ref } from "vue";
import { IItem, ItemType } from "@/models/item";
import { IOffer } from "@/models/offer";
import { useStore } from "@/store";
import ACounter from "@/components/controls/ACounter.vue";
import { IBasketItem } from "@/models/basketItem";
import { $vfm } from "vue-final-modal";
import TheBasket from "@/components/TheBasket.vue";
import TheTest from "@/components/TheTest.vue";
import TheDetail from "@/components/TheDetail.vue";
import TheNotification from "@/components/TheNotification.vue";
import CatalogItemDetail from "@/components/CatalogItemDetail.vue";
import Api from "@/services/api";
import { toast } from "vue3-toastify";
import NotificationContainer from "./NotificationContainer.vue";

let noPhoto = require("@/assets/img/no-photo.png");

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    // ACounter
  },
  props: {
    item: {
      type: Object as PropType<IItem>,
      required: true,
    },
  },
  data() {
    return {
      // selectedOffer: null,
      minOffer: {} as IOffer,
      qt: 5,
      invalid: false,
      loading: false,
      tm: 0,
    };
  },
  mounted() {
    if (this.item.type == ItemType.WitOffers) {
      this.minOffer = (this.item.offers as Array<IOffer>)[0];
      (this.item.offers as Array<IOffer>).forEach((offer) => {
        if (this.minOffer.ratioPrice > offer.ratioPrice) {
          this.minOffer = offer;
        }
      });
    }
  },
  computed: {
    price() {
      if (this.item.type == 3 && !this.selectedOffer) {
        if (this.minOffer) return this.minOffer.price;
      } else if (this.item.type == 3) {
        return (this.selectedOffer as IOffer).price;
      }
      return this.item.price;
    },
    ratio() {
      if (this.item.type == 3 && !this.selectedOffer) {
        if (this.minOffer) return this.minOffer.ratio;
      } else if (this.item.type == 3 && this.selectedOffer) {
        return this.selectedOffer.ratio;
      }
      return this.item.ratio;
    },
    measure() {
      console.log(this.item);
      if (this.item.type == 3 && !this.selectedOffer) {
        if (this.minOffer) return this.minOffer.measure;
      } else if (this.item.type == 3) {
        return (this.selectedOffer as IOffer).measure;
      }
      return this.item.measure;
    },
    available() {
      if (this.item.type == 3 && !this.selectedOffer) {
        if (this.minOffer) return this.minOffer.available;
      } else if (this.item.type == 3) {
        return (this.selectedOffer as IOffer).available;
      }
      return this.item.available;
    },
    displayRatioPrice() {
      if (this.selectedOffer && this.item.offers) {
        console.log(this.selectedOffer.ratioPrice);
        // console.log(this.item.offers[this.selectedOffer.id]);
        // return this.item.offers[].ratioPrice
        return null;
      } else {
        return null;
      }
    },
    displayPrice() {
      if (this.item.type == 3 && !this.selectedOffer) {
        if (this.minOffer)
          return "от  " + this.priceFormat(this.minOffer?.price);
      } else if (this.item.type == 3 && this.selectedOffer) {
        return this.priceFormat(this.selectedOffer.price);
      }
      return this.priceFormat(this.item.price);
    },
    displayDiscountPrice() {
      if (this.item.type == 3 && this.selectedOffer?.discountRatio) {
        return this.priceFormat(
          (this.selectedOffer as IOffer).discountRatio +
            (this.selectedOffer as IOffer).ratioPrice
        );
      } else if (this.item.type == 1 && this.item.discountRatio) {
        return this.priceFormat(this.item.discountRatio + this.item.ratioPrice);
      }
      return null;
    },
    displayDiscountPercent() {
      if (this.item.type == 3 && this.selectedOffer) {
        return (this.selectedOffer as IOffer).discountPercent;
      } else if (this.item.type == 1) {
        return this.item.discountPercent;
      }

      return 0;
    },
    displayInfoRatio() {
      if (this.item.type == 3 && !this.selectedOffer) {
        if (this.minOffer && this.minOffer.ratio != 1) {
          return this.minOffer.ratio * 1000 + "г";
        }
      } else if (this.item.type == 3 && this.selectedOffer) {
        if (this.selectedOffer && this.selectedOffer.ratio != 1) {
          return this.selectedOffer.ratio * 1000 + "г";
        }
      } else if (this.item.ratio != 1) {
        return this.item.ratio * 1000 + "г";
      }
      return null;
    },
    displayInfoPrice() {
      if (this.item.type == 3 && !this.selectedOffer) {
        if (this.minOffer && this.minOffer.ratio != 1) {
          return (
            this.priceFormat(this.minOffer.price) + "/" + this.minOffer.measure
          );
        }
      } else if (this.item.type == 3 && this.selectedOffer) {
        if (this.selectedOffer && this.selectedOffer.ratio != 1) {
          return (
            this.priceFormat(this.selectedOffer.price) +
            "/" +
            this.selectedOffer.measure
          );
        }
      } else if (this.item.ratio != 1) {
        return this.priceFormat(this.item.price) + "/" + this.item.measure;
      }
      return null;
    },

    displayInfo() {
      if (this.item.type == 3 && !this.selectedOffer) {
        if (this.minOffer && this.minOffer.ratio != 1) {
          return (
            this.minOffer.ratio * 1000 +
            " г • " +
            this.priceFormat(this.minOffer.price) +
            "/" +
            this.minOffer.measure
          );
        }
      } else if (this.item.type == 3 && this.selectedOffer) {
        if (this.selectedOffer && this.selectedOffer.ratio != 1) {
          return (
            this.selectedOffer.ratio * 1000 +
            " г • " +
            this.priceFormat(this.selectedOffer.price) +
            "/" +
            this.selectedOffer.measure
          );
        }
      } else if (this.item.ratio != 1) {
        return (
          this.item.ratio * 1000 +
          "г • " +
          this.priceFormat(this.item.price) +
          "/" +
          this.item.measure
        );
      }
      return null;
    },
    displayImages() {
      let images: Array<string> = [];

      if (this.item.type == 3 && !this.selectedOffer) {
        let offers = this.item.offers;
        if (offers && offers.length > 0) {
          images = offers[0].images;
        }
      } else if (this.item.type == 3 && this.selectedOffer) {
        images = this.selectedOffer.images;
      } else {
        images = this.item.images;
      }

      if (images.length == 0) {
        images.push(noPhoto);
      }

      return images;
    },
    quantity() {
      if (this.item.type == 3 && this.selectedOffer) {
        let item: IBasketItem | undefined = this.store.state.basket.items.find(
          (item) => item.productId == (this.selectedOffer as IOffer).id
        );
        if (item) return item.quantity;
      } else {
        let item: IBasketItem | undefined = this.store.state.basket.items.find(
          (item) => item.productId == this.item.id
        );
        if (item) return item.quantity;
      }
      return 0;
    },
    inBasket() {
      if (this.item.type == 3 && this.selectedOffer) {
        return this.basketItemIds.includes(this.selectedOffer.id);
      } else {
        return this.basketItemIds.includes(this.item.id);
      }
    },
  },
  methods: {
    showDetail(item) {
      $vfm.show({
        component: TheDetail,
        bind: {
          title: "Ваш заказ",
        },
        slots: {
          content: {
            component: CatalogItemDetail,
            bind: {
              item: item,
            },
          },
        },
      });
    },
    showNotification(id) {
      $vfm.show({
        component: TheNotification,
        bind: {
          title: "Уведомить о поступлении",
        },
        slots: {
          content: {
            component: NotificationContainer,
            bind: {
              id: id,
            },
          },
        },
      });
    },
    showBasket() {
      $vfm.show({
        component: TheTest,
        bind: {
          title: "Ваш заказ",
        },
        slots: {
          content: {
            component: TheBasket,
          },
        },
      });
    },
    priceFormat(value: number) {
      return Intl.NumberFormat().format(value) + " ₽";
    },
    changeSelectedOffer(offer: IOffer) {
      this.selectedOffer = offer;
    },
    addInvalid() {
      if (this.item.type == 3 && !this.selectedOffer) {
        this.invalid = false;
        if (this.tm) clearTimeout(this.tm);
        this.tm = setTimeout(() => (this.invalid = true), 1);
        this.tm = setTimeout(() => (this.invalid = false), 2000);
        toast("Выберите грейд", {
          position: "bottom-right",
          theme: "dark",
          progressStyle: {
            background: "green",
          },
          type: "warning",
        });
      } else {
        this.addBasket();
      }
    },
    addBasket() {
      if (this.item.type == 1) {
        this.addBasketRequest({
          productId: this.item.id,
          quantity: this.item.ratio,
        });
      } else if (this.item.type == 3 && this.selectedOffer) {
        this.addBasketRequest({
          productId: (this.selectedOffer as IOffer).id,
          quantity: (this.selectedOffer as IOffer).ratio,
        });
      }
    },
    addBasketRequest(data) {
      this.loading = true;
      this.api
        .addBasket(data)
        .then((resp) => {
          let data = resp.data;
          this.store.commit("setBasket", data);
        })
        .catch((err) => {
          let data = err.response.data;
          let mesages = data.errors.map((item) => item.message).join(", ");
          toast(mesages, {
            position: "bottom-right",
            theme: "dark",
            progressStyle: {
              background: "green",
            },
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDetail(item: IItem) {
      this.store.commit("showDialog", {
        name: "product",
        data: item,
      });

      // this.$emit('openInfoPopup', meatItem)
    },
    // changeBasket(qt: number) {
    //   if (this.item.type == 1) {
    //     this.store
    //       .dispatch("addBasket", {
    //         productId: this.item.id,
    //         quantity: this.item.ratio,
    //       })
    //       .then(() => {
    //         console.log("end");
    //       });
    //   } else if (this.item.type == 3 && this.selectedOffer) {
    //     this.store
    //       .dispatch("addBasket", {
    //         productId: this.selectedOffer.id,
    //         quantity: this.selectedOffer.ratio,
    //       })
    //       .then(() => {
    //         console.log("end");
    //       });
    //   }
    // },
  },
  setup() {
    const store = useStore();

    let selectedOffer = ref<null | IOffer>(null);
    let selectedOfferOut = false;

    const api = inject<Api>("api", () => new Api(), true);
    return {
      api,
      selectedOffer,
      selectedOfferOut,
      store,
      basketItemIds: computed(() =>
        store.state.basket.items.map((item) => item.productId)
      ),
      modules: [Pagination],
    };
  },
});
