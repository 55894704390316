
import { $vfm } from "vue-final-modal";

interface IError {
  code: number;
  message: string;
}

interface IOtp {
  timeout: number;
  attemptsLeft: number;
  message: string;
  codeLength: number;
}

import { computed, defineComponent, inject, PropType, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { useStore } from "@/store";
import { prop } from "vue-class-component";
import Api from "@/services/api";

export default defineComponent({
  data() {
    return {
      loading: false,
    };
  },
  props: {
    phone: {
      type: String,
      required: true,
    },
    otp: {
      type: Object as PropType<IOtp>,
      required: true,
    },
  },

  emits: ['success'],
  methods:{
    submit(){
      this.loading = true;
      this.v$.$validate().then((result)=> {
        if(result){
          this.errors = [];
          this.api.authLogin({
            phone: this.phone,
            code: this.code
          }).then((data) => {
            if($vfm.dynamicModals[$vfm.dynamicModals.length-1]){
              $vfm.dynamicModals[$vfm.dynamicModals.length-1].value = false;

              this.store.commit('setAuthorized', true);
              this.store.commit('setUser', data.data);

              this.$emit('success',true);
            }
          })
          .catch((err)=>{
            let data = err.response.data;
            this.errors = data.errors;
          }).finally(()=>{
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      });
    },
    sendNewCode() {
      this.api
        .authGetCode({
          phone: this.phone,
        })
        .then(() => {
          this.startTimer();
        })
        .catch((err) => {
          let data = err.response.data;
          this.errors = data.errors;
        });
    },
    startTimer() {
      if (this.tm) clearInterval(this.tm);
      this.timer = this.otp.timeout;
      this.tm = setInterval(() => {
        this.timer -= 1;
        if (this.timer <= 0) {
          clearInterval(this.tm);
        }
      }, 1000);
    },
  },
  mounted() {
    this.startTimer();
  },
  validations() {
    return {
      code: {
        required: helpers.withMessage("Обязательное поле", required),
      },
    };
  },
  setup(props) {
    const store = useStore();

    let errors = ref<Array<IError>>([]);

    const api = inject<Api>("api", () => new Api(), true);

    let code = ref<string | null>(null);

    let maskCode = "#".repeat(props.otp.codeLength);

    let timer = ref<number>(props.otp.timeout);

    let tm: any = null;

    return {
      maskCode,
      code,
      api,
      errors,
      store,
      timer,
      tm,
      v$: useVuelidate(),
    };
  },
});
