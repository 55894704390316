
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      basketInfo: false,
      isLogin: true,
    };
  },
  methods: {
    openBasket() {
      this.basketInfo = true;
    },
    openPhone() {
      this.basketInfo = false;
      this.$emit("openPhone", true);
    },
    openAccount() {
      this.basketInfo = false;
      this.$emit("openAccount", true);
    },
    openPay() {
      this.basketInfo = false;
      this.$emit("openPay", true);
    },
  },
  watch: {
    basketInfo(event) {
      if (event == true) {
        document.querySelector("html")?.classList.add("html--active");
      }
      if (event == false) {
        document.querySelector("html")?.classList.remove("html--active");
      }
    },
  },
});
