
import { computed, defineComponent } from "vue";
import Drawer_basket from "./../components/Drawer_basket.vue";
import Drawer_pay from "./../components/Drawer_pay.vue";
import Dialog_accepted from "./../components/Dialog_accepted.vue";
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { IOffer } from "@/models/offer";
import { useStore } from "@/store";
import { $vfm } from "vue-final-modal";
import TheTest from "@/components/TheTest.vue";
import PickUpLocation from "@/components/PickUpLocation.vue";
import AuthPhone from "@/components/AuthPhone.vue";
import AuthPhoneConfirm from "@/components/AuthPhoneConfirm.vue";
import TheAccount from "@/components/TheAccount.vue";
import TheBasket from "@/components/TheBasket.vue";
import AuthAccount from "@/components/AuthAccount.vue";
import TheSearch from "@/components/TheSearch.vue";

export default defineComponent({
  components: {
    Drawer_basket,
    Drawer_pay,
    Dialog_accepted,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      previousScrollPosition: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      let containers = document.querySelectorAll(".app__container");
      let sectionsTitle = document.querySelectorAll(
        ".main-header__list-info-item"
      );
      let containerTitle = document.querySelector(".main-header__bottom");
      let observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          let marginTop = 45;
          if (
            document.querySelector(".main-header__mobile-header--active-header")
          ) {
            marginTop = 110;
          } else {
            marginTop = 50;
          }
          if (
            entry.isIntersecting &&
            entry.intersectionRect.y < marginTop &&
            window.innerWidth < 600
          ) {
            sectionsTitle.forEach((sectionTitle) => {
              if (
                sectionTitle.getAttribute("data-section-title") ==
                entry.target.getAttribute("data-section")
              ) {
                if (
                  !entry.target.classList.contains("app__container--active")
                ) {
                  containers.forEach((container) => {
                    container.classList.remove("app__container--active");
                  });
                  entry.target.classList.add("app__container--active");
                  containerTitle?.scrollTo(
                    sectionTitle.getBoundingClientRect().left - 16,
                    0
                  );
                }
              }
            });
          }
        });
      });

      containers.forEach((container) => observer.observe(container));

      if (window.innerWidth < 600) {
        if (this.isScrollingDown()) {
          if (window.scrollY > 60) {
            document
              .querySelector(".main-header__mobile-header")
              ?.classList.add("main-header__mobile-header--active");

            document
              .querySelector(".main-header__mobile-header")
              ?.classList.remove("main-header__mobile-header--active-header");
          }
        } else {
          if (window.scrollY > 60) {
            document
              .querySelector(".main-header__mobile-header")
              ?.classList.add("main-header__mobile-header--active-header");
          } else {
            document
              .querySelector(".main-header__mobile-header")
              ?.classList.remove("main-header__mobile-header--active-header");
          }
        }
      }
    });
  },
  methods: {
    openSearch() {
      $vfm.show({
        component: TheSearch,
      });
    },
    showAuth() {
      if (this.isAuthorized) {
        $vfm.show({
          component: TheAccount,
          bind: { title: "Личный кабинет" },
          slots: {
            content: {
              component: AuthAccount,
            },
          },
        });
      } else {
        $vfm.show({
          component: TheTest,
          bind: { title: "" },
          slots: {
            content: {
              component: AuthPhone,
              bind: {
                success: function () {
                  $vfm.show({
                    component: TheAccount,
                    bind: { title: "Личный кабинет" },
                    slots: {
                      content: {
                        component: AuthAccount,
                      },
                    },
                  });
                },
              },
            },
          },
        });
      }
    },

    showBasket() {
      $vfm.show({
        component: TheTest,
        bind: {
          title: "Ваш заказ",
        },
        slots: {
          content: {
            component: TheBasket,
          },
        },
      });
    },

    isScrollingDown() {
      let goingDown = false;

      let scrollPosition = window.pageYOffset;

      if (scrollPosition > this.previousScrollPosition) {
        goingDown = true;
      }

      this.previousScrollPosition = scrollPosition;
      return goingDown;
    },
    gotToSectionById(sectionId: number) {
      let section = document.querySelector(
        '[data-section="' + sectionId + '"]'
      );
      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    goToBlock(code: string) {
      this.onCloseMenu();
      let section = document.querySelector('[data-block="' + code + '"]');

      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    onOpenMenu() {
      document
        .querySelector(".menu-popup")
        ?.classList.add("menu-popup--active");
      document.querySelector("html")?.classList.add("html--active");
    },
    onCloseMenu() {
      document
        .querySelector(".menu-popup")
        ?.classList.remove("menu-popup--active");
      document.querySelector("html")?.classList.remove("html--active");
    },
    onOpenMenuTablet() {
      document
        .querySelector(".menu-popup-tablet")
        ?.classList.add("menu-popup-tablet--active");
      document.querySelector("html")?.classList.add("html--active");
    },
    onCloseMenuTablet() {
      document
        .querySelector(".menu-popup-tablet")
        ?.classList.remove("menu-popup-tablet--active");
      document.querySelector("html")?.classList.remove("html--active");
    },
    openBasketPopup() {
      this.store.commit("showDialog", {
        name: "basket",
        data: null,
      });
    },
    openPay() {
      (this.$refs["openPay"] as any).openPay();
    },
    openPhone() {
      this.$emit("openPhone", true);
    },
    openAccount() {
      this.$emit("openAccount", true);
    },
    backDrawerPay() {
      this.openBasketPopup();
    },
    openAccepted() {
      (this.$refs["openAccepted"] as any).openAccepted();
    },
    openHistory() {
      this.$emit("openHistory", true);
    },
  },
  setup() {
    const store = useStore();

    return {
      store,
      isAuthorized: computed(() => store.state.isAuthorized),
      catalog: computed(() => store.state.catalog),
    };
  },
});
