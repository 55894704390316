
import {computed, defineComponent} from "vue";
import {useStore} from "@/store";
import CatalogItem from "@/components/CatalogItem.vue";

export default defineComponent({
  props: {
    code: {
      type: String,
      required: true,
      defaul: ''
    },
  },
  components: {
    CatalogItem
  },
  mounted() {
    console.log([this.code,this.catalog]);
  },
  methods:{
  },
  computed:{
    items(){
      return this.catalog.items.filter(item=>item['piece'] == this.code);
    }
  },
  setup() {
    const store = useStore();

    return {
      store,
      catalog: computed(() => store.state.catalog),
    };
  },
});
